import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import './PlanosFibra.css';
import ModalVerMaisPremium from '../ModalVerMaisPremium';
import ModalVerMaisStandard from '../ModalVerMaisStandard';
import { useNavigate } from 'react-router-dom';
import VelocidadePlanos from '../DadosPlanos/VelocidadePlanos';
import Modals from '../Modals';
import SaibaMaisLecupon from '../SaibaMaisLecupon';
import GradeCanais from '../GradeCanais';


export default function PlanoResidencialTV() {


    const navigate = useNavigate()

    //velocidade dos planos via props
    const lite = 550;
    const basic = 650;
    const plus = 750;
    const ultra = 900;

    const uploadLite = 100;
    const uploadBasic = 150;
    const uploadPlus = 250;
    const uploadUltra = 450;


    const premium = <div class="d-inline gap-2 d-sm-flex justify-content-sm-center ms-3">
        <img className="ms-1" height="20" width="20" src='https://hdflgabrcqndyvcnyjtn.supabase.co/storage/v1/object/public/imagens/apps/ritualfit' />
        <img className="ms-1" height="20" width="20" src='https://hdflgabrcqndyvcnyjtn.supabase.co/storage/v1/object/public/imagens/apps/doutorpass' />
        <img className="ms-1" height="20" width="20" src='https://hdflgabrcqndyvcnyjtn.supabase.co/storage/v1/object/public/imagens/apps/hbomax' />
        <ModalVerMaisPremium />
    </div>;

    const standard = <div className="d-inline gap-2 d-sm-flex justify-content-sm-center ms-3">
        <img className="ms-1" height="20" width="20" src='https://hdflgabrcqndyvcnyjtn.supabase.co/storage/v1/object/public/imagens/apps/playkids' />
        <img className="ms-1" height="20" width="20" src='https://hdflgabrcqndyvcnyjtn.supabase.co/storage/v1/object/public/imagens/apps/deezer' />
        <img className="ms-1" height="20" width="20" src='https://hdflgabrcqndyvcnyjtn.supabase.co/storage/v1/object/public/imagens/apps/looke' />
        <ModalVerMaisStandard />
    </div>;

    const positivo = <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="#008000" fill-rule="evenodd" d="M12 21a9 9 0 1 0 0-18a9 9 0 0 0 0 18m-.232-5.36l5-6l-1.536-1.28l-4.3 5.159l-2.225-2.226l-1.414 1.414l3 3l.774.774z" clip-rule="evenodd" /></svg>;
    const negativo = <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="#ff0000" d="M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10s10-4.47 10-10S17.53 2 12 2m5 13.59L15.59 17L12 13.41L8.41 17L7 15.59L10.59 12L7 8.41L8.41 7L12 10.59L15.59 7L17 8.41L13.41 12z" /></svg>;








    //informações do planos de internet
    const planos = [
        {
            titulo: "Combo Lite",
            mega: lite,
            upload: uploadLite,
            detalhes: "lite",
            valor: "119,90",
            link: "/lite-tv",
            roteador: <li><svg xmlns="https://www.w3.org/2000/svg" height="20" width="20" className='me-1 text-muted'>
                <path fill='#001a4c' d="M4.375 17.083q-.667 0-1.135-.458-.469-.458-.469-1.125v-2.646q0-.666.469-1.125.468-.458 1.135-.458h7.937V8.792q0-.271.209-.469.208-.198.458-.198.271 0 .469.198.198.198.198.469v2.479h1.708q.667 0 1.125.458.459.459.459 1.125V15.5q0 .667-.459 1.125-.458.458-1.125.458Zm10.979-1.333q.125 0 .188-.073.062-.073.062-.177v-2.646q0-.104-.062-.177-.063-.073-.188-.073H4.375q-.125 0-.198.073-.073.073-.073.177V15.5q0 .104.073.177t.198.073Zm-8.896-1.562q0-.313-.208-.521-.208-.209-.542-.209-.312 0-.52.209-.209.208-.209.521 0 .312.209.52.208.209.52.209.334 0 .542-.209.208-.208.208-.52Zm2.834 0q0-.313-.209-.521-.208-.209-.541-.209-.313 0-.521.209-.209.208-.209.521 0 .312.209.52.208.209.521.209.333 0 .541-.209.209-.208.209-.52Zm2.083.729q.333 0 .542-.209.208-.208.208-.52 0-.313-.208-.521-.209-.209-.542-.209-.313 0-.521.209-.208.208-.208.521 0 .312.208.52.208.209.521.209Zm-.521-8.271q-.208-.208-.198-.479.011-.271.24-.417.458-.333.979-.5.521-.167 1.104-.167.604 0 1.104.167.5.167.979.5.23.146.25.406.021.261-.208.49-.166.187-.427.208-.26.021-.573-.125-.25-.125-.521-.219-.271-.093-.583-.093-.333 0-.615.093-.281.094-.531.219-.312.146-.573.125-.26-.021-.427-.208ZM8.667 4.5q-.209-.208-.188-.458.021-.25.229-.438.875-.708 1.959-1.114 1.083-.407 2.312-.407 1.229 0 2.313.407 1.083.406 1.979 1.114.208.188.219.448.01.26-.219.469-.167.167-.438.187-.271.021-.5-.166-.666-.542-1.5-.834-.833-.291-1.854-.291-1 0-1.833.291-.834.292-1.5.834-.229.187-.511.156-.281-.031-.468-.198ZM4.104 15.75v-3.146 3.146Z" />
            </svg>WI-FI AC 1200 Mbps</li>,
            repetidor: negativo,
            clube: positivo,
            telefone: negativo,
            ncanais: 60,
            canais: <Modals
                title="Pacote 1 de Canais de TV:"
                buttonClass="small ms-1"
                buttonName="Ver Grade"
                conteudo={<GradeCanais bg="bg-gradiente" pacote="https://hdflgabrcqndyvcnyjtn.supabase.co/storage/v1/object/public/imagens/grade%20canais/pacote-um.jpg?t=2024-10-31T18%3A34%3A24.353Z" />}
            />,
            box: negativo,
            mudanca: negativo,
            premium: negativo,
            standard: negativo,


        },
        {
            status: <span className="advanced">Popular</span>,
            titulo: "Combo Basic",
            mega: basic,
            upload: uploadBasic,
            detalhes: "basic",
            valor: "139,90",
            link: "/basic-tv",
            roteador: <li><svg xmlns="https://www.w3.org/2000/svg" height="20" width="20" className='me-1 text-muted'>
                <path fill='#001a4c' d="M4.375 17.083q-.667 0-1.135-.458-.469-.458-.469-1.125v-2.646q0-.666.469-1.125.468-.458 1.135-.458h7.937V8.792q0-.271.209-.469.208-.198.458-.198.271 0 .469.198.198.198.198.469v2.479h1.708q.667 0 1.125.458.459.459.459 1.125V15.5q0 .667-.459 1.125-.458.458-1.125.458Zm10.979-1.333q.125 0 .188-.073.062-.073.062-.177v-2.646q0-.104-.062-.177-.063-.073-.188-.073H4.375q-.125 0-.198.073-.073.073-.073.177V15.5q0 .104.073.177t.198.073Zm-8.896-1.562q0-.313-.208-.521-.208-.209-.542-.209-.312 0-.52.209-.209.208-.209.521 0 .312.209.52.208.209.52.209.334 0 .542-.209.208-.208.208-.52Zm2.834 0q0-.313-.209-.521-.208-.209-.541-.209-.313 0-.521.209-.209.208-.209.521 0 .312.209.52.208.209.521.209.333 0 .541-.209.209-.208.209-.52Zm2.083.729q.333 0 .542-.209.208-.208.208-.52 0-.313-.208-.521-.209-.209-.542-.209-.313 0-.521.209-.208.208-.208.521 0 .312.208.52.208.209.521.209Zm-.521-8.271q-.208-.208-.198-.479.011-.271.24-.417.458-.333.979-.5.521-.167 1.104-.167.604 0 1.104.167.5.167.979.5.23.146.25.406.021.261-.208.49-.166.187-.427.208-.26.021-.573-.125-.25-.125-.521-.219-.271-.093-.583-.093-.333 0-.615.093-.281.094-.531.219-.312.146-.573.125-.26-.021-.427-.208ZM8.667 4.5q-.209-.208-.188-.458.021-.25.229-.438.875-.708 1.959-1.114 1.083-.407 2.312-.407 1.229 0 2.313.407 1.083.406 1.979 1.114.208.188.219.448.01.26-.219.469-.167.167-.438.187-.271.021-.5-.166-.666-.542-1.5-.834-.833-.291-1.854-.291-1 0-1.833.291-.834.292-1.5.834-.229.187-.511.156-.281-.031-.468-.198ZM4.104 15.75v-3.146 3.146Z" />
            </svg>WI-FI AC 1200 Mbps</li>,
            repetidor: negativo,
            clube: positivo,
            telefone: negativo,
            ncanais: 104,
            canais: <Modals
                title="Pacote 2 de Canais de TV:"
                buttonClass="small ms-1"
                buttonName="Ver Grade"
                conteudo={<GradeCanais bg="bg-verde" pacote="https://hdflgabrcqndyvcnyjtn.supabase.co/storage/v1/object/public/imagens/grade%20canais/pacote-dois.jpg?t=2024-10-31T18%3A04%3A37.852Z" />}
            />,
            filmes: positivo,
            box: positivo,
            mudanca: negativo,
            premium: negativo,
            standard: negativo,


        },
        {
            status: <span className="advanced">Recomendado</span>,
            titulo: "Combo Plus",
            mega: plus,
            upload: uploadPlus,
            detalhes: "plus",
            valor: "179,90",
            link: "/plus-tv",
            roteador: <li><svg xmlns="https://www.w3.org/2000/svg" height="20" width="20" className=' me-1 text-muted'>
                <path fill='#001a4c' d="M4.375 17.083q-.667 0-1.135-.458-.469-.458-.469-1.125v-2.646q0-.666.469-1.125.468-.458 1.135-.458h7.937V8.792q0-.271.209-.469.208-.198.458-.198.271 0 .469.198.198.198.198.469v2.479h1.708q.667 0 1.125.458.459.459.459 1.125V15.5q0 .667-.459 1.125-.458.458-1.125.458Zm10.979-1.333q.125 0 .188-.073.062-.073.062-.177v-2.646q0-.104-.062-.177-.063-.073-.188-.073H4.375q-.125 0-.198.073-.073.073-.073.177V15.5q0 .104.073.177t.198.073Zm-8.896-1.562q0-.313-.208-.521-.208-.209-.542-.209-.312 0-.52.209-.209.208-.209.521 0 .312.209.52.208.209.52.209.334 0 .542-.209.208-.208.208-.52Zm2.834 0q0-.313-.209-.521-.208-.209-.541-.209-.313 0-.521.209-.209.208-.209.521 0 .312.209.52.208.209.521.209.333 0 .541-.209.209-.208.209-.52Zm2.083.729q.333 0 .542-.209.208-.208.208-.52 0-.313-.208-.521-.209-.209-.542-.209-.313 0-.521.209-.208.208-.208.521 0 .312.208.52.208.209.521.209Zm-.521-8.271q-.208-.208-.198-.479.011-.271.24-.417.458-.333.979-.5.521-.167 1.104-.167.604 0 1.104.167.5.167.979.5.23.146.25.406.021.261-.208.49-.166.187-.427.208-.26.021-.573-.125-.25-.125-.521-.219-.271-.093-.583-.093-.333 0-.615.093-.281.094-.531.219-.312.146-.573.125-.26-.021-.427-.208ZM8.667 4.5q-.209-.208-.188-.458.021-.25.229-.438.875-.708 1.959-1.114 1.083-.407 2.312-.407 1.229 0 2.313.407 1.083.406 1.979 1.114.208.188.219.448.01.26-.219.469-.167.167-.438.187-.271.021-.5-.166-.666-.542-1.5-.834-.833-.291-1.854-.291-1 0-1.833.291-.834.292-1.5.834-.229.187-.511.156-.281-.031-.468-.198ZM4.104 15.75v-3.146 3.146Z" />
            </svg>WI-FI 6 1500 Mbps</li>,
            repetidor: positivo,
            clube: positivo,
            telefone: positivo,
            ncanais: 104,
            canais: <Modals
                title="Pacote 2 de Canais de TV:"
                buttonClass="small ms-1"
                buttonName="Ver Grade"
                conteudo={<GradeCanais bg="bg-verde" pacote="https://hdflgabrcqndyvcnyjtn.supabase.co/storage/v1/object/public/imagens/grade%20canais/pacote-dois.jpg?t=2024-10-31T18%3A04%3A37.852Z" />}
            />,
            box: positivo,
            mudanca: positivo,
            premium: " +1",
            standard: "  +1",
            iconPremium: premium,
            iconStandard: standard,

        },
        {

            titulo: "Combo Ultra",
            mega: ultra,
            upload: uploadUltra,
            detalhes: "ultra",
            valor: "219,90",
            link: "/ultra-tv",
            roteador: <li><svg xmlns="https://www.w3.org/2000/svg" height="20" width="20" className='me-1 text-muted'>
                <path fill='#001a4c' d="M4.375 17.083q-.667 0-1.135-.458-.469-.458-.469-1.125v-2.646q0-.666.469-1.125.468-.458 1.135-.458h7.937V8.792q0-.271.209-.469.208-.198.458-.198.271 0 .469.198.198.198.198.469v2.479h1.708q.667 0 1.125.458.459.459.459 1.125V15.5q0 .667-.459 1.125-.458.458-1.125.458Zm10.979-1.333q.125 0 .188-.073.062-.073.062-.177v-2.646q0-.104-.062-.177-.063-.073-.188-.073H4.375q-.125 0-.198.073-.073.073-.073.177V15.5q0 .104.073.177t.198.073Zm-8.896-1.562q0-.313-.208-.521-.208-.209-.542-.209-.312 0-.52.209-.209.208-.209.521 0 .312.209.52.208.209.52.209.334 0 .542-.209.208-.208.208-.52Zm2.834 0q0-.313-.209-.521-.208-.209-.541-.209-.313 0-.521.209-.209.208-.209.521 0 .312.209.52.208.209.521.209.333 0 .541-.209.209-.208.209-.52Zm2.083.729q.333 0 .542-.209.208-.208.208-.52 0-.313-.208-.521-.209-.209-.542-.209-.313 0-.521.209-.208.208-.208.521 0 .312.208.52.208.209.521.209Zm-.521-8.271q-.208-.208-.198-.479.011-.271.24-.417.458-.333.979-.5.521-.167 1.104-.167.604 0 1.104.167.5.167.979.5.23.146.25.406.021.261-.208.49-.166.187-.427.208-.26.021-.573-.125-.25-.125-.521-.219-.271-.093-.583-.093-.333 0-.615.093-.281.094-.531.219-.312.146-.573.125-.26-.021-.427-.208ZM8.667 4.5q-.209-.208-.188-.458.021-.25.229-.438.875-.708 1.959-1.114 1.083-.407 2.312-.407 1.229 0 2.313.407 1.083.406 1.979 1.114.208.188.219.448.01.26-.219.469-.167.167-.438.187-.271.021-.5-.166-.666-.542-1.5-.834-.833-.291-1.854-.291-1 0-1.833.291-.834.292-1.5.834-.229.187-.511.156-.281-.031-.468-.198ZM4.104 15.75v-3.146 3.146Z" />
            </svg>WI-FI 6 1500 Mbps</li>,
            repetidor: positivo,
            clube: positivo,
            telefone: positivo,
            ncanais: 104,
            canais: <Modals
                title="Pacote 2 de Canais de TV:"
                buttonClass="small ms-1"
                buttonName="Ver Grade"
                conteudo={<GradeCanais bg="bg-verde" pacote="https://hdflgabrcqndyvcnyjtn.supabase.co/storage/v1/object/public/imagens/grade%20canais/pacote-dois.jpg?t=2024-10-31T18%3A04%3A37.852Z" />}
            />,
            box: positivo,
            mudanca: positivo,
            premium: " +1 ",
            standard: "  +1",
            iconPremium: premium,
            iconStandard: standard,

        },
    ]
    //fim do planos
    const string = "#";

    return (
        <>
            <div id="card-fibra" className=" d-flex mx-0 justify-content-center row row-cols-1 row-cols-md-3 mb-3 text-center ">
                {planos.map(({
                    status,
                    titulo,
                    mega,
                    upload,
                    roteador,
                    clube,
                    canais,
                    filmes,
                    repetidor,
                    box,
                    telefone,
                    mudanca,
                    premium,
                    iconPremium,
                    standard,
                    iconStandard,
                    valor,
                    link,
                    detalhes,
                    ncanais


                }, key) => {
                    return (
                        <div className="col-md-3" key={planos.toString()}>

                            <div className="card mb-4 ">
                                <div className='card-planos'>
                                    {status}
                                    <h4 className="title my-0 "> {titulo} </h4>
                                    <div className="card-body ">
                                        <small className='text-muted fs-6'>DOWNLOAD</small>
                                        <h3 className="my-0 mb-3 offer_title text-center fw-light"> <span className='text-mega fw-semibold'>{mega}</span>MEGA</h3>
                                        <hr />
                                        <small className='text-muted fs-6'>UPLOAD</small>
                                        <h5 className="my-0 mb-3 offer_title text-center fw-light"> <span className='text-mega fw-semibold'>{upload}</span>MEGA</h5>
                                        <hr />
                                        {/* <p class="d-inline-flex gap-1">
                                            <a class="link-orange" data-bs-toggle="collapse" href={`${string}${detalhes}`} role="button" aria-expanded="false" aria-controls="collapseExample">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 15 15"><path fill="#ff5e00" d="M8.293 2.293a1 1 0 0 1 1.414 0l4.5 4.5a1 1 0 0 1 0 1.414l-4.5 4.5a1 1 0 0 1-1.414-1.414L11 8.5H1.5a1 1 0 0 1 0-2H11L8.293 3.707a1 1 0 0 1 0-1.414" /></svg> Veja Mais Benefícios Aqui
                                            </a>
                                        </p> */}
                                        {/* <div id={detalhes} className="card-footer pb-5 collapse"> */}
                                        <div  className="card-footer">
                                            {/* <div id="ultra-collapse-text"> */}
                                          
                                                <ul className="list-unstyled mt-3 mb-4 text-start text-light">
                                                    {roteador}
                                                    <li>{clube} Clube de Descontos <a href="#lecupon"><small>Saber Mais</small></a></li>
                                                    <li>{positivo} {ncanais} Canais de TV {canais}
                                                    </li>
                                                    <li>{box} 1 TV BOX <a href="#tvbox"><small>Saber Mais</small></a></li>
                                                    <li>{repetidor} 1 Repetidor de sinal</li>
                                                    <li>{telefone} Telefone fixo <a href="#telefone"><small>Saber Mais</small></a></li>
                                                    <li>{mudanca} 1 Mudança no Ano</li>
                                                    <li className='mt-2'> {premium} app's Premium</li>
                                                    {iconPremium}
                                                    <li className='mt-2'>{standard} app's Standard</li>
                                                    {iconStandard}
                                                </ul>
                                            {/* </div> */}
                                        </div>
                                        <h2 className="valor pricing-card-title text-center align-bottom">R${valor}<small className="text-muted fw-light"><span className="fs-5"> /mês </span></small></h2>

                                        <button type="button" className="w-100 btn btn-lg btn-primary mt-3" onClick={() => navigate(`${link}`)}>Assinar</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    )

                })}
            </div>

            <h2 className="display-6 text-center mb-4">Compare os Combo</h2>
            <div className="table-responsive rounded-3 p-0 d-flex m-auto">
                <table className="table text-center">
                    <thead>
                        <tr>
                            <th></th>
                            <th className='fs-3'>Combo Lite </th>
                            <th className='fs-3'>Combo Basic</th>
                            <th className='fs-3'>Combo Plus</th>
                            <th className='fs-3'>Combo Ultra</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row" className="text-start">Velocidade de Download
                                {['top'].map((placement) => (
                                    <OverlayTrigger trigger="click" key={placement} placement={placement}
                                        overlay={
                                            <Popover id={`popover-positioned-${placement}`}>
                                                <Popover.Body>
                                                    <strong>Download:</strong> A velocidade para baixar um arquivo da Internet para um dispositivo, como imagens, vídeos, músicas e documentos.
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill ms-2" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                        </svg>
                                    </OverlayTrigger>
                                ))}
                            </th>
                            <td>
                                <b><VelocidadePlanos velocidade={lite} /> mbps</b>
                            </td>
                            <td>
                                <b><VelocidadePlanos velocidade={basic} /> mbps</b>
                            </td>
                            <td>
                                <b><VelocidadePlanos velocidade={plus} /> mbps</b>
                            </td>
                            <td>
                                <b><VelocidadePlanos velocidade={ultra} /> mbps</b>
                            </td>

                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Velocidade de Upload
                                {['top'].map((placement) => (
                                    <OverlayTrigger trigger="click" key={placement} placement={placement}
                                        overlay={
                                            <Popover id={`popover-positioned-${placement}`}>
                                                <Popover.Body>
                                                    <strong>upload:</strong> A velocidade para enviar uma imagens, vídeos, músicas e documentos.
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill ms-2" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                        </svg>
                                    </OverlayTrigger>
                                ))}
                            </th>
                            <td>
                                <b><VelocidadePlanos velocidade={uploadLite} /> mbps</b>
                            </td>
                            <td>
                                <b><VelocidadePlanos velocidade={uploadBasic} /> mbps</b>
                            </td>
                            <td>
                                <b><VelocidadePlanos velocidade={uploadPlus} /> mbps</b>
                            </td>
                            <td>
                                <b><VelocidadePlanos velocidade={uploadUltra} /> mbps</b>
                            </td>

                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Canais de TV
                                {['top'].map((placement) => (
                                    <OverlayTrigger
                                        trigger="click"
                                        key={placement}
                                        placement={placement}
                                        overlay={
                                            <Popover id={`popover-positioned-${placement}`}>
                                                <Popover.Body>
                                                    Acesso a todos os Canais de Tv dentro do Netsim Play.
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill ms-2" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                        </svg>
                                    </OverlayTrigger>
                                ))}
                            </th>
                            <td> <b>Pacote 1</b></td>
                            <td> <b>Pacote 2</b></td>
                            <td> <b>Pacote 2</b></td>
                            <td> <b>Pacote 2</b></td>

                        </tr>
                            <tr>
                                <th scope="row" className="text-start">App's Premium
                                    {['top'].map((placement) => (
                                        <OverlayTrigger
                                            trigger="click"
                                            key={placement}
                                            placement={placement}
                                            overlay={
                                                <Popover id={`popover-positioned-${placement}`}>
                                                    <Popover.Body>
                                                        <strong> 4 app's</strong> de esporte, filme e etc.
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill ms-2" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                            </svg>
                                        </OverlayTrigger>
                                    ))}
                                </th>
                                <td>
                                    {negativo}

                                </td>
                                <td>
                                    {negativo}
                                </td>
                                <td><b>1</b></td>
                                <td><b>1</b></td>
                            </tr>

                            <tr>
                                <th scope="row" className="text-start">App's Standard
                                    {['top'].map((placement) => (
                                        <OverlayTrigger
                                            trigger="click"
                                            key={placement}
                                            placement={placement}
                                            overlay={
                                                <Popover id={`popover-positioned-${placement}`}>
                                                    <Popover.Body>
                                                        <strong>16 app's</strong> diferentes de filme, esporte, musica, documentarios, desenhos e etc.
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill ms-2" viewBox="0 0 16 16">
                                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                            </svg>
                                        </OverlayTrigger>
                                    ))}
                                </th>
                                <td>
                                    {negativo}
                                </td>
                                <td><b>{negativo}</b></td>
                                <td><b>1</b></td>
                                <td><b>1</b></td>
                            </tr>






                        <tr>
                            <th scope="row" className="text-start">WI-FI 6

                                {['top'].map((placement) => (
                                    <OverlayTrigger
                                        trigger="click"
                                        key={placement}
                                        placement={placement}
                                        overlay={
                                            <Popover id={`popover-positioned-${placement}`}>
                                                <Popover.Body>
                                                    O<strong> Wi-Fi 6 </strong> é o novo padrão que representa a sexta geração de internet sem fio.
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill ms-2" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                        </svg>
                                    </OverlayTrigger>
                                ))}
                            </th>
                            <td>
                                {negativo}
                            </td>
                            <td>
                                {negativo}
                            </td>
                            <td>
                                {positivo} </td>
                            <td>
                                {positivo}   </td>
                        </tr>

                        <tr>
                            <th scope="row" className="text-start">Repetidor de Sinal
                                {['top'].map((placement) => (
                                    <OverlayTrigger
                                        trigger="click"
                                        key={placement}
                                        placement={placement}
                                        overlay={
                                            <Popover id={`popover-positioned-${placement}`}>
                                                <Popover.Body>
                                                    <strong>Repetidor</strong> amplifica o sinal do Wifi gerado pelo roteador para que a rede alcance áreas que o sinal não chega...
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill ms-2" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                        </svg>
                                    </OverlayTrigger>
                                ))}
                            </th>
                            <td>
                                {negativo}

                            </td>
                            <td>
                                {negativo}

                            </td>
                            <td>    {positivo}   </td>
                            <td>
                                {positivo} </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">TV BOX
                                {['top'].map((placement) => (
                                    <OverlayTrigger
                                        trigger="click"
                                        key={placement}
                                        placement={placement}
                                        overlay={
                                            <Popover id={`popover-positioned-${placement}`}>
                                                <Popover.Body>
                                                    Ao contratar o plano de internet vai incluso um TV BOX para tornar sua TV SMART e desfrutar do NetSim Play TV
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill ms-2" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                        </svg>
                                    </OverlayTrigger>
                                ))}
                            </th>
                            <td>
                                {negativo}

                            </td>
                            <td>
                                {negativo}

                            </td>
                            <td>   {positivo}  </td>
                            <td>
                                {positivo}    </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">Telefone fixo
                                {['top'].map((placement) => (
                                    <OverlayTrigger trigger="click" key={placement} placement={placement}
                                        overlay={
                                            <Popover id={`popover-positioned-${placement}`}>
                                                <Popover.Body>
                                                    <strong>Linha de telefone fixo</strong> com 100 minutos para qualquer operadora e recebimento ilimitado.
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill ms-2" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                        </svg>
                                    </OverlayTrigger>
                                ))}
                            </th>
                            <td>
                                {negativo}
                            </td>
                            <td>
                                {negativo}
                            </td>
                            <td>
                                {positivo} </td>
                            <td>
                                {positivo}  </td>
                        </tr>
                        <tr>
                            <th scope="row" className="text-start">1 Mudança de Endereço Grátis no Ano
                                {['top'].map((placement) => (
                                    <OverlayTrigger
                                        trigger="click"
                                        key={placement}
                                        placement={placement}
                                        overlay={
                                            <Popover id={`popover-positioned-${placement}`}>
                                                <Popover.Body>
                                                    Caso você tenha que mudar do endereço atual, a Netsim leva sua internet para o endereço novo, sem você precisar pagar taxa.
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill ms-2" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                        </svg>
                                    </OverlayTrigger>
                                ))}
                            </th>
                            <td>
                                {negativo}

                            </td>
                            <td>
                                {negativo}

                            </td>
                            <td>  {positivo}</td>
                            <td>
                                {positivo}  </td>
                        </tr>

                        <tr>
                            <th scope="row" className="text-start">Clube de Descontos
                                {['top'].map((placement) => (
                                    <OverlayTrigger
                                        trigger="click"
                                        key={placement}
                                        placement={placement}
                                        overlay={
                                            <Popover id={`popover-positioned-${placement}`}>
                                                <Popover.Body>
                                                    <strong>Aplicativo</strong> que garante descontos em produtos de vários estabelecimentos em sua região e pelo Brasil.
                                                </Popover.Body>
                                            </Popover>
                                        }
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle-fill ms-2" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 4a.905.905 0 0 0-.9.995l.35 3.507a.552.552 0 0 0 1.1 0l.35-3.507A.905.905 0 0 0 8 4zm.002 6a1 1 0 1 0 0 2 1 1 0 0 0 0-2z" />
                                        </svg>
                                    </OverlayTrigger>
                                ))}
                            </th>
                            <td>
                                {positivo}
                            </td>
                            <td>
                                {positivo}    </td>
                            <td>
                                {positivo}  </td>
                            <td>
                                {positivo}</td>
                        </tr>


                    </tbody>


                </table>
            </div>

        </>
    );
}
